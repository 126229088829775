import { useEffect, useState } from 'react'

import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import services from './services'

const useMicroGoal = ({ setIsLoading, loading, changeLeadData }) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [radioOptions, setRadioOptions] = useState([])
  const [subTitle, setSubTitle] = useState(false)

  const handleOptions = () => {
    setIsLoading(true)

    services
      .getMicroGoal()
      .then(({ data, previous_step }) => {
        setSubTitle(data?.subtitle)
        setRadioOptions(data?.options)

        changeLeadData({
          previousStep: previous_step
        })
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    handleOptions()
  }, [])

  const onSubmit = async (payload) => {
    setSubmitLoading(true)

    const { goal } = payload

    analytics.track('buttonClick', {
      currentPage: 'formulario-micro-objetivo',
      detail: 'click-step-micro-objetivo',
      customLayer: {
        description: `micro-objetivo-${goal}`
      }
    })

    const requestBody = { micro_goal_id: Number(goal) }

    return services
      .putMicroGoal(requestBody)
      .then(({ next_step }) => {
        changeLeadData(requestBody)
        goTo(next_step)
      })
      .catch(() => setSubmitLoading(false))
  }

  return { onSubmit, loading, submitLoading, radioOptions, subTitle }
}

export default useMicroGoal
